import "./news-page.scss";
import { useEffect, useState, useContext } from "react";
import { Form, Input, Modal, Select, Switch, Checkbox, Spin } from "antd";
import ProductCard from "../components/product-card";
import { useUserContext } from "../context/dashboard-context";
import axios from "axios";
import ImageUploader from "../components/uploader";
import Table from "react-bootstrap/Table";
import CustomPagination from "../components/pagination";
import formatPrice from "../utills";
import ImageUploadContext from "../context/ImageUploadContext";

const ProductsPage = () => {
  const [form] = Form.useForm();
  const {
    token,
    categorys,
    GetCategory,
    brands,
    setImages,
    setShowimg,
    setFileList,
    fileList,
  } = useUserContext();
  const { clearImages, images } = useContext(ImageUploadContext);

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("page")) || 1
  );
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [brandImg, setBrandImg] = useState();
  const [catproduct, setCatproduct] = useState(null);
  const [img, setImg] = useState([]);
  const [title, setTitle] = useState("");
  const [titleRu, setTitleRu] = useState("");
  const [desc, setDec] = useState("");
  const [descRu, setDecRu] = useState("");
  const [excerpt, setExcerpt] = useState(false);
  const [price, setPrice] = useState("");
  const [categoryId, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [brandState, setBrandState] = useState();
  const [switchbutton, setSwitch] = useState(excerpt);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clear, setClear] = useState(false);
  const [selectBoxValue, setSelectBoxValue] = useState();
  const [avaibleSale, setAvaibleSale] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const GetProducts = (pageNumber = 1) => {
    setLoading(true);
    axios
      .get(
        catproduct
          ? `https://api.protool.uz/v1/products/?category.oid=${catproduct}`
          : `https://api.protool.uz/v1/products?limit=12&page=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setProducts(response.data.data);
        setTotalProducts(response.data.totalCount);
        setPagesCount(response.data.pagesCount);
      });
  };

  useEffect(() => {
    GetProducts();
  }, [catproduct]);

  const getBrandImage = async (brand) => {
    const brandImage =
      brands.find((element) => element.name === brand)?.image || "";
    return brandImage;
  };

  const onFinish = async () => {
    setLoadingCreate(true);
    form.resetFields();
    const requestData = {
      title: title,
      images: images,
      description: desc,
      excerpt: excerpt ? "true" : "false",
      price: price ? parseInt(price) : 0,
      category: categoryId,
      additionalInfos: [
        { key: "brand", value: brandState },
        { key: "titleRu", value: titleRu },
        { key: "descriptionRu", value: descRu },
        { key: "brandimage", value: await getBrandImage(brandState) },
      ],
    };
    axios
      .post("https://api.protool.uz/v1/products", requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        GetProducts();
        form.resetFields();
        setLoading(true);
        setClear(true);
        setFileList([]);
        clearImages();
        handleOk();
        setLoadingCreate(false);
      })
      .catch((error) => {
        console.error("Error adding product:", error);
        setLoadingCreate(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("page", JSON.stringify(page));
    GetProducts(page);
  };

  const showModal = () => {
    setIsModalOpen(true);
    GetCategory();
    setImages([]);
    setClear(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setImages([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setClear(true);
    setFileList([]);
    clearImages();
    form.resetFields();
  };

  const GetSearch = (value) => {
    axios
      .get(`https://api.protool.uz/v1/products?search=${value}`)
      .then((response) => {
        setProducts(response.data.data);
        setTotalProducts(response.data.totalCount);
      });
  };
  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Mahsulotlar</h1>
          <button onClick={showModal}>+ Mahsulot qo’shish</button>
        </div>
        <div className="mb-4 d-flex" style={{ gap: "10px" }}>
          <div>
            <Checkbox onChange={() => setAvaibleSale(!avaibleSale)}>
              Sotuvda mavjud
            </Checkbox>
          </div>
          <Form.Item name="brand">
            <Input
              onChange={(e) => GetSearch(e.target.value)}
              placeholder="Qidirish"
            />
          </Form.Item>
          <Form.Item name="brand">
            <Select
              allowClear
              placeholder="Brand tanlash"
              onChange={(value) => setSelectBoxValue(value)}
            >
              {brands?.map((i) => (
                <Select.Option key={i._id} value={i.name}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="brand">
            <Select
              allowClear
              placeholder="Kategoriya tanlash"
              onChange={(value) => setCatproduct(value)}
            >
              {categorys?.map((i) => (
                <Select.Option key={i._id} value={i._id}>
                  {i.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Spin spinning={loading}>
          <Table>
            <thead>
              <tr>
                <th>Nomi</th>
                <th>Tafsifi</th>
                <th>Narxi</th>
                <th>Rasm</th>
                <th>Tahrirlash</th>
              </tr>
            </thead>
            <tbody>
              {products
                ?.filter((item) =>
                  avaibleSale ? item.excerpt === "true" : true
                )
                .filter((item) =>
                  selectBoxValue
                    ? item.additionalInfos?.[0]?.value === selectBoxValue
                    : true
                )
                ?.map((i) => {
                  if (i._id == '669d3b562d763a9a7af978f8') {
                    console.log(i.category);
                  }
                return(
                  <tr key={i._id}>
                    <ProductCard
                      key={i?._id}
                      GetProducts={GetProducts}
                      featuredproduct={i?.featured}
                      data={i?.additionalInfos}
                      id={i?._id}
                      type={"products"}
                      img={i?.images[0]}
                      title={i.title}
                      excerpt={i?.excerpt}
                      description={i.description}
                      price={i?.price}
                      category={i?.category}
                    />
                  </tr>
                )})}
            </tbody>
          </Table>
          <CustomPagination
            setCurrentPage={setCurrentPage}
            current={currentPage}
            func={GetProducts}
            defaultCurrent={1}
            total={totalProducts}
            onChange={handlePageChange}
          />
        </Spin>
      </div>
      <div className="modal-news">
        <Modal
          title="Mahsulot qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Spin spinning={loadingCreate}>
            <div className="modal-body">
              <div className="body-img">
                <Form form={form} layout="vertical" onFinish={onFinish}>
                  <div>
                    <ImageUploader setClear={setClear} clear={clear} />
                    {/* {fileList.length === 0 && (
                      <p style={{ color: "red" }}>Rasm tanlash majburiy</p>
                    )} */}
                  </div>
                  <div className="my-3 d-flex justify-content-between">
                    <label> Sotuvda mavjud</label>
                    <Switch onClick={() => setExcerpt(!excerpt)} />
                  </div>
                  <Form.Item
                    label="Nomi uz"
                    name="title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input
                      placeholder="Nomi"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nomi ru"
                    name="titleru"
                    rules={[{ required: true, message: "TitleRu is required" }]}
                  >
                    <Input
                      placeholder="Nomi Ru"
                      onChange={(e) => setTitleRu(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Tafsifi uz"
                    name="description"
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <Input
                      placeholder="Tafsifi"
                      onChange={(e) => setDec(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Tafsifi ru"
                    name="descriptionru"
                    rules={[
                      { required: true, message: "Description Ru is required" },
                    ]}
                  >
                    <Input
                      placeholder="Tafsifi Ru"
                      onChange={(e) => setDecRu(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Narxi"
                    name="price"
                    rules={[{ required: true, message: "Price is required" }]}
                  >
                    <Input
                      placeholder="Narxi"
                      type="number"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Kategoriya"
                    name="category"
                    rules={[
                      { required: true, message: "Category is required" },
                    ]}
                  >
                    <Select
                      placeholder="Kategoriya tanlash"
                      onChange={(value) => setCategory(value)}
                    >
                      {categorys?.map((i) => (
                        <Select.Option key={i._id} value={i._id}>
                          {i.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Brendi"
                    name="brand"
                    rules={[{ required: true, message: "Brand is required" }]}
                  >
                    <Select
                      placeholder="Brand tanlash"
                      onChange={(value) => setBrandState(value)}
                    >
                      {brands?.map((i) => (
                        <Select.Option key={i._id} value={i.name}>
                          {i.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item className="text-end">
                    <button type="submit" className="submit">
                      Saqlash
                    </button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </>
  );
};

export default ProductsPage;
