import { useEffect, useState } from "react";
import { useUserContext } from "../context/dashboard-context";
import Table from "react-bootstrap/Table";
import { downloadExcel } from "react-export-table-to-excel";
import axios from "axios";
import { Select } from "antd";
import CustomPagination from "../components/pagination";
const Orders = () => {
  const [order, setOrder] = useState([]);
  const { setTotalProducts, setPagesCount, currentPage, totalProducts } =
    useUserContext();
  const [selectId, setSelectId] = useState();
  // const handleChange = (selectedOption) => {
  //   console.log(selectedOption, "select");
  //   // Tanlangan qiymatning id va status sifatida qilishingizni tekshiring
  //   // Tanlangan qiymatning id va statusini olish
  //   // const { key: id, status } = selectedOption;
  //   // API ga so'rov yuboring
  //   axios
  //     .put("https://api2.protools.uz/api/update", {
  //       id: selectedOption?.key,
  //       status: selectedOption?.value,
  //     })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Server response:", data);
  //       // Serverdan kelgan javobni ishlang
  //     })
  //     .catch((error) => {
  //       console.error("There was a problem with the fetch operation:", error);
  //       // Xatolikni qayta ishlash yoki foydalanuvchiga xabar berish
  //     });
  // };

  const GetOrders = (fuckingPageNumber) => {
    axios
      .get(
        `https://back2.protools.uz/api/order/?include=maxsulot`,
        {}
      )
      .then((response) => {
        setOrder(response?.data?.data);
        console.log(response, "resp");
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };

  const handleChange = (selectedOption, orderId) => {
    const { value: status } = selectedOption;
    axios
      .put("https://api2.protools.uz/api/update", {
        id: orderId,
        status: status,
      })
      .then(() => {
        setOrder((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: status } : order
          )
        );
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  useEffect(() => {
    GetOrders();
  }, []);

  return (
    <>
      <style>
        {`
      .ant-select-selector{ 
        border: transparent !important;
      }
      `}
      </style>
      <Table bordered size="sm" style={{ marginTop: "30px" }}>
        <thead>
          <tr>
            <th>Ism</th>
            <th>Tel</th>
            <th>Mahsulot</th>
            <th>Soni</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {order.map((order) => (
            <tr key={order._id}>
              <td>{order.name}</td>
              <td>
                <a
                  style={{ color: "black", textDecoration: "underline" }}
                  href={`tel:+${order.phone_number}`}
                >
                  {order.phone_number}
                </a>
              </td>
              <td>
                <a href={order.productLink}>{order?.maxsulot?.name_uz}</a>
              </td>
              <td>{order.count}</td>
              <td style={{ width: "140px" }}>
                <Select
                  labelInValue
                  defaultValue={{ value: order.status, label: order.status }}
                  style={{
                    width: 130,
                    textAlign: "center",
                    borderRadius: "6px",
                    border:
                      order.status === "yangi"
                        ? "1px solid #979797"
                        : order.status === "bekor qilingan"
                        ? "1px solid #E31E24"
                        : order.status === "qabul qilindi"
                        ? "1px solid #4DB839"
                        : order.status === "Yetkazib berish"
                        ? "1px solid #0E5F21"
                        : order.status === "yakunlangan"
                        ? "1px solid #2E80ED"
                        : "transparent",
                  }}
                  onChange={(selectedOption) =>
                    handleChange(selectedOption, order._id)
                  }
                  options={[
                    {
                      value: "yangi",
                      label: "yangi",
                      style: { border: "1px solid #979797" },
                    },
                    {
                      value: "bekor qilingan",
                      label: "bekor qilingan",
                      style: { border: "1px solid #E31E24" },
                    },
                    {
                      value: "qabul qilindi",
                      label: "qabul qilindi",
                      style: { border: "1px solid #4DB839" },
                    },
                    {
                      value: "Yetkazib berish",
                      label: "Yetkazib berish",
                      style: { border: "1px solid #0E5F21" },
                    },
                    {
                      value: "yakunlangan",
                      label: "yakunlangan",
                      style: { border: "1px solid #2E80ED" },
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export default Orders;
