import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo-grey.png";
import {
  CategoryIcon,
  Logout,
  NewsIcon,
  ProductsIcon,
  Star,
} from "../../assets/images/icons";
import "./sidebar.scss";
import { useState } from "react";
import Loader from "../loading";

const Sidebar = () => {
  const currentPath = window.location.pathname;
  console.log(currentPath, "pathname");
  const navigate = useNavigate();


  const handleHardRefresh = () => {
    // Append a random query parameter to the URL
    const randomQueryParam = `?random=${Math.random()}`;
    window.location.href = window.location.href + randomQueryParam;
  };
  

  const pageChanger = () => {

    // 2 sekundlik kechikishni o'rnatish
      localStorage.setItem("page", JSON.stringify(1));
      // handleHardRefresh();
  };

  return (
    <div className="sidebar">
      {/* {isLoading && <Loader/>} */}
      <div>
        <div className="sidebar-top">
          <div className="sidebar-logo">
            <Link onClick={() => navigate("/admin/news")} to={"/admin/news"}>
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div className="menu">
            <button
              onClick={() => {
                navigate("/admin/news");
                pageChanger()
              }}
              className={currentPath === "/admin/news" ? "link active" : "link"}
            >
              <NewsIcon />
              Yangiliklar
            </button>
            <button
              onClick={() => {
                navigate("/admin/products");
                pageChanger()
              }}
              className={
                currentPath === "/admin/products" ? "link active " : "link"
              }
            >
              <ProductsIcon />
              Mahsulotlar
            </button>
            <button
              onClick={() => {
                navigate("/admin/zapchast/products");
                pageChanger()
              }}
              className={
                currentPath === "/admin/zapchast/products" ? "link active " : "link"
              }
            >
              <ProductsIcon />
              Zapchast mahsulotlar
            </button>
            <button
              onClick={() => {
                navigate("/admin/zapchast");
                pageChanger()
              }}
              className={
                currentPath === "/admin/zapchast" ? "link active " : "link"
              }
            >
              <ProductsIcon />
              Zapchastlar
            </button>
            <button
              onClick={() => {
                navigate("/admin/category");
                pageChanger()
              }}
              className={
                currentPath === "/admin/category" ? "link active " : "link"
              }
            >
              <CategoryIcon />
              Kategoriyalar
            </button>

            <button
              onClick={() => {
                navigate("/admin/brends");
                pageChanger()
              }}
              className={
                currentPath === "/admin/brends" ? "link active " : "link"
              }
            >
              <Star />
              Brendlar
            </button>
            <button
              onClick={() => {
                navigate("/admin/banners");
                pageChanger()
              }}
              className={
                currentPath === "/admin/banners" ? "link active " : "link"
              }
            >
              <Star />
              Bannerlar
            </button>
            <button
              onClick={() => {
                navigate("/admin/orders");
                pageChanger()
              }}
              className={
                currentPath === "/admin/orders" ? "link active " : "link"
              }
            >
              <Star />
              Buyurtmalar
            </button>
          </div>
        </div>
        <div className="sidebar-bottom">
          <Link onClick={() => localStorage.removeItem("token")} to={"/"}>
            <Logout />
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
