import { useUserContext } from "../../context/dashboard-context";
import CounterCard from "../card-counter";
import "./admin-about.scss";

const AdminAbout = () => {
  const { categorys, news, products, brands } = useUserContext()
  const data = [

    {
      //   icon: <User />,
      title: "Barcha kategoriyalar",
      count: categorys?.length,
    },
    {
      //   icon: { Users },
      title: "Barcha brendlar",
      count: brands?.length,
    },
    {
      //   icon: <User />,
      title: "Barcha mahsulotlar",
      count: products?.length,
    },
    {
      //   icon: <User />,
      title: "Barcha Yangiliklar",
      count: news?.length,
    }

  ];
  return (
    <>
      <div className="admin-about">
        <h1>Admin </h1>
        <div className="about-cards">
          {data?.map((i, k) => (
            <CounterCard icon={i.icon} title={i.title} count={i.count} />
          ))}
        </div>
      </div>
    </>
  );
};
export default AdminAbout;
