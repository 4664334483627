import React, { useState, useEffect } from 'react';
import ExampleComponent from './example';
// import ExampleImg from 'ozizi rasmizi urli'

const CountdownTimer = () => {
  const [seconds, setSeconds] = useState(3600);



  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div>
      <h1>Countdown Timer</h1>
      <div style={{
        display:"flex",
        color:"white"
      }}>
        <h1>{hours.toString().padStart(2, '0')}:</h1>
        <h1>{minutes.toString().padStart(2, '0')}:</h1>
        <h1>{remainingSeconds.toString().padStart(2, '0')}</h1>


        {/* <ExampleComponent img={ExampleImg}/>  */}
      </div>
    </div>
  );
};

export default CountdownTimer;
