import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Pagination, Spin, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import SearchInput from "../components/search";
import { useUserContext } from "../context/dashboard-context";
import Bannercard from "../components/banner-card/Bannercard";
import ImageUploader from "../components/uploader";
import { useImageUpload } from "../context/ImageUploadContext";

const BannersPage = () => {
  const [form] = Form.useForm();

  const {
    token,
    banners,
    GetBanners,
    currentPage,
    totalProducts,
    setCurrentPage,
  } = useUserContext();
  const { images ,clearImages} = useImageUpload();
  const handlePageChange = (page) => {
    console.log(page, "page");
    setCurrentPage(page);
    GetBanners(page);
  };
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState();
  const [value, setValue] = useState();
  const [description, setDescription] = useState();
  const [link, setLink] = useState();
  const [name, setName] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const [clear, setClear] = useState(false);
  const handleCancel = () => {
    clearImages()
    setIsModalOpen(false);
    setClear(true);
    form.resetFields();
  };
  const createBanner = () => {
    setLoading(true);
    axios
      .post(
        "https://api.protool.uz/v1/banners",
        {
          name: value,
          title: value,
          description: description,
          imageUrl: images[0],
          link: link,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response, "resp");
        if (response.status === 201) {
          form.resetFields();
        }
        GetBanners();
        message.success("Banner qo`shildi");
        clearImages()
        setLoading(false);
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error("Error creating banner:", error);
        message.error("Error creating banner");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (img) {
      createBanner();
    }
  }, []);

  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Banners</h1>
          <button onClick={showModal}>+ Banner qo’shish</button>
        </div>
        {/* <SearchInput endp={"banners"} /> */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
          className="page-cards"
        >
          {banners?.map((banner, index) => (
            <Bannercard
              id={banner._id}
              type={"banners"}
              img={banner.imageUrl}
              title={banner.title}
              description={banner.description}
              name={banner.name}
              link={banner.link}
              key={index}
              //   7.1
            />
          ))}
        </div>
        {/* <Pagination
          current={currentPage}
          defaultCurrent={1}
          total={totalProducts}
          onChange={handlePageChange}
        /> */}
      </div>
      <div className="modal-news">
        <Modal
          title="Banner qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Spin spinning={loading}>
            <div className="modal-body">
              <div className="body-img">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={() => {
                    createBanner();
                    // handleOk();
                  }}
                >
                  <ImageUploader clear={clear} />
                  <Form.Item
                    label="Nomi uz"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the banner name",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Banner nomi"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mavzu"
                    name="value"
                    rules={[
                      { required: true, message: "Please enter the text" },
                    ]}
                  >
                    <Input
                      onChange={(e) => setValue(e.target.value)}
                      placeholder="Maksimum 2500 ta so’z"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Link"
                    name="link"
                    rules={[
                      { required: true, message: "Please enter the link" },
                    ]}
                  >
                    <Input
                      onChange={(e) => setLink(e.target.value)}
                      placeholder="https://example.com"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Tafsifi"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the banner description",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Banner tafsifi"
                    />
                  </Form.Item>
                  <div className="modal-footer">
                    <button onClick={handleCancel}>
                      <span>Bekor qilish</span>
                    </button>
                    <button>
                      <span>Qo'shish</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </>
  );
};

export default BannersPage;
