// ImageUploadContext.js
import React, { createContext, useState, useContext } from "react";

const ImageUploadContext = createContext();

export const useImageUpload = () => {
  const context = useContext(ImageUploadContext);
  if (!context) {
    throw new Error(
      "useImageUpload must be used within an ImageUploadProvider"
    );
  }
  return context;
};

export const ImageUploadProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  console.log(images, "daskldmasldaskldmasl");

  const addImage = (image) => {
    setImages([...images, image]);
  };

  const clearImages = (image) => {
    setImages([]);
  };

  return (
    <ImageUploadContext.Provider value={{ images, addImage, clearImages }}>
      {children}
    </ImageUploadContext.Provider>
  );
};

export default ImageUploadContext;
