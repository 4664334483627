import { useEffect, useState } from "react";
import { Form, Input, Modal, Pagination } from "antd";
import { message } from "antd";
import axios from "axios";
import { useUserContext } from "../context/dashboard-context";
import SearchInput from "../components/search";
import Categorycard from "../components/categorycard/Categorycard";
import "./news-page.scss";
import ImageUploader from "../components/uploader";
import { useParams } from "react-router";
import { useImageUpload } from "../context/ImageUploadContext";

const SubCategoriesPage = () => {
  const [form] = Form.useForm();

  const [categoryId, setCategoryId] = useState();
  const [categoryIdProduct, setCategoryIdProducts] = useState();
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const {
    token,
    category,
    // GetCategory,
    setCategory,
    currentPage,
    totalProducts,
    setCurrentPage,
  } = useUserContext();
  const {images}=useImageUpload()
  const [categorys, setCategorys] = useState()
  const GetCategory = () => {
    axios
      .get(
        `https://api.protool.uz/v1/categories?limit=12?sortBy=createdAt&orderBy=DESC`,
        {}
      )
      .then((response) => {
        console.log(response, 'misha');
        setCategorys(response?.data?.data);
      });
  };
  const handlePageChange = (page) => {
    console.log(page, "page");
    setCurrentPage(page);
    GetCategory(page);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const { id } = useParams();
  const GetCategoryId = () => {
    axios
      .get(`https://api.protool.uz/v1/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCategoryIdProducts(response?.data?.data);
        console.log(response);
      });
  };
  console.log(categoryIdProduct, "categoryIdProduct");
  useEffect(() => {
    GetCategory();
    GetCategoryId();
  }, []);

  const [value, setValue] = useState();
  const [valueru, setValueRu] = useState();
  const [imageUrl, setImageUrl] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [img, setImg] = useState();

  const handleOk = () => {
    setIsModalOpen(false);
    setValue("");
    setImageUrl(null);
    setImg(null);
    AddCategory()
  };
  const [clear, setClear] = useState(false)
  const handleCancel = () => {
    setIsModalOpen(false);
    setClear(true)
  };
  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("file", imageUrl);

      const response = await axios.post(
        "https://api.protool.uz/v1/file-upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.data.message === "FILE_UPLOADED" &&
        response?.data?.data?.url
      ) {
        setImg(response.data.data.url);
        await AddCategory();
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const AddCategory = async () => {
    try {
      const response = await axios.post(
        "https://api.protool.uz/v1/categories",
        {
          title: value,
          slug: valueru,
          image: images[0],
          parent: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200 || response.status === 201) {
        GetCategory();
        form.resetFields();
        window.location.reload()
      }

      console.log(response.data.data);
    } catch (error) {
      console.error(error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "CATEGORY_NOT_FOUND"
      ) {
        console.log("Category not found");
      } else {
        console.log(error.response?.data.data);
      }
    }
  };

  useEffect(() => {
    if (img) {
      AddCategory();
    }
  }, [img]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageUrl(file);
    setImg(URL.createObjectURL(file));
  };
  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Kategoriyalar</h1>
          <button onClick={showModal}>+ Sub kategoriya qo’shish</button>
        </div>
        <SearchInput endp={`categories/${id}`} />
        <div className="page-cards">
          {categoryIdProduct?.children?.map((i, k) => (
            <Categorycard
              GetCategory={GetCategoryId}
              type={"categories"}
              slug={i.slug}
              id={i._id}
              img={i.image}
              title={i.title}
            />
          ))}
        </div>
      </div>
      <div className="modal-news">
        <Modal
          title="Kategoriya qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <div className="modal-body">
            <div className="body-img">
              <Form
                form={form}
                layout="vertical"
                onFinish={() => {
                  AddCategory();
                  // handleOk();
                }}
              >
                <ImageUploader clear={clear} />
                <Form.Item
                  label="Nomi"
                  name="Nomi"
                  rules={[{ required: true, message: "Title is required" }]}
                >
                  <Input
                    placeholder="Nomi"
                    onChange={(e) => setValue(e.target.value)}
                  // rules={[{ required: true, message: "Nomi is required" }]}
                  />
                </Form.Item>
                <Form.Item
                  label="Nomi ru"
                  name="Nomi ru"
                  rules={[{ required: true, message: "Title is required" }]}
                >
                  <Input
                    placeholder="Nomi ru"
                    onChange={(e) => setValueRu(e.target.value)}
                  // rules={[{ required: true, message: "Nomi is required" }]}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={handleCancel}>
              <span>Bekor qilish</span>
            </button>
            <button
              onClick={() => {
                // AddCategory();
                handleOk();
              }}
            >
              <span>Qo'shish</span>
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default SubCategoriesPage;
