import { Route, Routes } from "react-router";
import LoginPage from "./pages/login-page";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/styles/main.scss";
import ProfileLayout from "./layout/profile-layout";
import { useUserContext } from "./context/dashboard-context";
import { useEffect, useState } from "react";
import Loader from "./components/loading";

function App() {
  const { token, loading } = useUserContext();
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Simulate an async operation, like fetching data or other setup
  //   const fetchData = async () => {
  //     // Replace this with your actual data fetching logic
  //     await new Promise((resolve) => setTimeout(resolve, 300));

  //     // Once the operation is done, set loading to false
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

  return (
    <div className="App">
      {/* {loading && <Loader />} */}
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {token && <Route path="/admin/*" element={<ProfileLayout />} />}
      </Routes>
    </div>
  );
}

export default App;
