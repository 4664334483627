import React, { useState } from "react";
import { Modal, Input, message, Spin } from "antd";
import { Pen, DeleteIcon } from "../../assets/images/icons";
import "./bannercard.scss";
import axios from "axios";
import { useUserContext } from "../../context/dashboard-context";
import ImageEditeUploader from "../imageEditeUploader";

const Bannercard = ({ link, img, title, description, id, type, name }) => {
  const { token, GetBanners } = useUserContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedLink, setUpdatedLink] = useState(link);
  const [updatedImage, setUpdatedImage] = useState(img);
  const [imageFile, setImageFile] = useState(null);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [value, setValue] = useState(name);
  const [loading, setLoading] = useState(false);

  const { images } = useUserContext();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setUpdatedImage(null)
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const removeBanner = () => {
    setLoading(true);
    axios
      .delete(`https://api.protool.uz/v1/${type}/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        GetBanners();
        handleOk();
        message.success("Banner o`chirildi");
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        message.error("Bannerni oʻchirib boʻlmadi");
        setLoading(false);
      });
  };
  const updateProduct = () => {
    setLoading(true);
    axios
      .put(
        `https://api.protool.uz/v1/${type}/${id}`,
        {
          name: value,
          title: updatedTitle,
          description: updatedDescription,
          imageUrl: updatedImage,
          link: updatedLink,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        GetBanners();
        handleOk();
        message.success("Banner yangilandi");
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Mahsulot yangilanmadi");
        setLoading(false);
      });
  };
  const updateBanner = () => {
    const formData = new FormData();
    formData.append("file", imageFile);

    axios
      .post("https://api.protool.uz/v1/file-upload", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const imageUrl = response.data.data.url || updatedImage;
        axios
          .put(
            `https://api.protool.uz/v1/${type}/${id}`,
            {
              imageUrl: imageUrl,
              link: updatedLink,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            GetBanners();
            handleOk();
            message.success("Banner yangilandi");

            setUpdatedTitle("");
            setUpdatedDescription("");
            setUpdatedLink("");
            setUpdatedImage(null);
            setImageFile(null);
          })
          .catch((err) => {
            console.error(err);
            if (err.response && err.response.status === 401) {
              localStorage.removeItem("token");
            }
            message.error("Bannerni yangilab bo‘lmadi");
          });
      })
      .catch((error) => {
        message.error("Bannerni yangilab bo‘lmadi");
      });
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="bannercard">
          <div className="card-img">
            <img src={img} alt="" />
            <div className="card-edit">
              <button onClick={showEditForm} className="card_edit">
                <Pen />
                O’zgartirish
              </button>
              <button onClick={removeBanner} className="card_delete">
                <DeleteIcon />
                O’chirish
              </button>
            </div>
          </div>
          <div className="card-text">
            <h1>{}</h1>
            <p>{}</p>
          </div>
        </div>
      </Spin>
      <Modal
        title={isEditMode ? "Update Banner" : "Delete Banner"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Spin spinning={loading}>
          {isEditMode ? (
            <div className="product_edit_card">
              <p className="product_edit_card_info">Bannerni o’zgartirish</p>
              <ImageEditeUploader
                setUpdatedImage={setUpdatedImage}
                updatedImage={updatedImage ? updatedImage : img}
                token={token}
              />
              <label htmlFor="title" className="bannercard_modal_title">
                Nomi
                <Input
                  id="name"
                  name="name"
                  placeholder="Nomi"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  style={{ marginBottom: "16px" }}
                  required
                />
              </label>
              <label htmlFor="description" className="bannercard_modal_title">
                Tafsifi uz
                <Input.TextArea
                  id="description"
                  name="description"
                  placeholder="Tafsifi"
                  value={updatedDescription}
                  onChange={(e) => setUpdatedDescription(e.target.value)}
                  style={{ marginBottom: "16px", resize: "none" }}
                  required
                />
              </label>
              <label htmlFor="title" className="bannercard_modal_title">
                Tafsifi ru
                <Input
                  id="title"
                  name="title"
                  placeholder="Mavzu"
                  value={updatedTitle}
                  onChange={(e) => setUpdatedTitle(e.target.value)}
                  style={{ marginBottom: "16px" }}
                  required
                />
              </label>

              <label htmlFor="link" className="bannercard_modal_title">
                Link
                <Input
                  id="link"
                  name="link"
                  placeholder="Link"
                  value={updatedLink}
                  onChange={(e) => setUpdatedLink(e.target.value)}
                  style={{ marginBottom: "16px" }}
                  required
                />
              </label>

              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button
                  className="bannercard_modal_save"
                  onClick={updateProduct}
                >
                  <span>Saqlash</span>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>Are you sure you want to delete this banner?</p>
              <div className="modal-footer">
                <button
                  onClick={handleCancel}
                  className="bannercard_modal_edit"
                >
                  <span>Bekor qilish</span>
                </button>
                <button
                  className="bannercard_modal_delete"
                  onClick={removeBanner}
                >
                  <span>o`chirish</span>
                </button>
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default Bannercard;
