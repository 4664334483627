import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useSearchParams } from "react-router-dom";
import { useUserContext } from "../../context/dashboard-context";

const CustomPagination = ({
  total,
  current,
  setCurrentPage,
  pageSize = 10,
  func,
}) => {
  // const { setCurrentPage } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const page = parseInt(searchParams.get("page")) || current;
    setCurrentPage(page);
  }, [searchParams, current]);
  const onPageChange = (page) => {
    setCurrentPage(page);
    searchParams.set("page", page);
    setSearchParams(searchParams);
    func(page);
  };
  return (
    <Pagination
      current={current}
      // defaultCurrent={1}
      pageSize={12}
      total={total}
      onChange={onPageChange}
      onShowSizeChange={onPageChange}
    />
  );
};

export default CustomPagination;
