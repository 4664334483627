import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Pagination, Spin } from "antd";
import { message } from "antd";
import axios from "axios";
import { useUserContext } from "../context/dashboard-context";
import SearchInput from "../components/search";
import Categorycard from "../components/categorycard/Categorycard";
import "./news-page.scss";
import ImageUploader from "../components/uploader";
import CustomPagination from "../components/pagination";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import ZapchastMainCard from "../components/zapchast-card";
import { useImageUpload } from "../context/ImageUploadContext";

const ZapchastMainPage = () => {
  const [form] = Form.useForm();
  const { token,  setImages } = useUserContext();
  const {images,clearImages}=useImageUpload()
  const [loading, setLoading] = useState(false);
  const [categorys, setCategorys] = useState();
  const [clear, setClear] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("page")),
    1
  );
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  // const page  = useParams()
  const [searchParams, setSearchParams] = useSearchParams();

  const GetCategory = (page = currentPage) => {
    setLoading(true);
    axios
      .get(`https://back2.protools.uz/api/zapchast/?per_page=30`, {})
      .then((response) => {
        console.log(response, "misha");
        setLoading(false);
        setCategorys(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [value, setValue] = useState();
  const [slug, setSlug] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [img, setImg] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setValue("");
    setImageUrl(null);
    setImg(null);
    clearImages()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setImages([]);
    clearImages()
  };
  const AddCategory = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("name_uz", value);
    formdata.append("name_ru", slug);
    formdata.append("image", images[0]);
    try {
      const response = await axios.post(
        "https://back2.protools.uz/api/zapchast/",
        formdata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log(response);
      if (response.status === 200 || response.status === 201) {
        console.log("succes");
        form.resetFields();
        // window.location.reload()
        GetCategory();
        setLoading(false);
        handleOk()
        clearImages()
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "CATEGORY_NOT_FOUND"
      ) {
        console.log("Category not found");
      } else {
        console.log(error.response?.data.data);
      }
    }
  };

  useEffect(() => {
    GetCategory();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageUrl(file);
    setImg(URL.createObjectURL(file));
  };

  const GetSearch = (value) => {
    axios
      .get(`https://back2.protools.uz/api/zapchast/?per_page=30&search=${value}`)
      .then((response) => {
        setCategorys(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
      });
  };
  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Zapchast mahsulotlar</h1>
          <button onClick={showModal}>+ Zapchast qo’shish</button>
        </div>
        <div className="mb-4">
          <Input
            onChange={(e) => GetSearch(e.target.value)}
            placeholder="Qidirish"
          />
        </div>
        <Spin spinning={loading}>
          <div className="page-cards">
            {categorys?.reverse().map((i, k) => (
              <ZapchastMainCard
                key={i.id}
                getProducts={GetCategory}
                data={i}
                id={i.id}
                img={i.image}
                title={i.name_uz}
                title_ru={i.name_ru}
              />
            ))}
          </div>
        </Spin>
        <CustomPagination
          func={GetCategory}
          current={currentPage}
          defaultCurrent={1}
          total={totalProducts}
          setCurrentPage={setCurrentPage}
          // onChange={handlePageChange}
        />
      </div>
      <div className="modal-news">
        <Modal
          title="Kategoriya qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Spin spinning={loading}>
            <Form
              form={form}
              onFinish={(e) => {
                AddCategory();
                //   handleOk();
                //   e.preventDefault();
              }}
              layout="vertical"
            >
              <div className="body-img">
                <ImageUploader  />
              </div>
              <Form.Item
                label="Nomi uz"
                name="name"
                rules={[{ required: true, message: "Majburiy !" }]}
              >
                <Input
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="nomi"
                />
              </Form.Item>

              <Form.Item
                label="Nomi ru"
                name="nameru"
                rules={[{ required: true, message: "Majburiy !" }]}
              >
                <Input
                  onChange={(e) => setSlug(e.target.value)}
                  placeholder="nomi ru"
                />
              </Form.Item>

              <div className="modal-footer">
                <Button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </Button>
                <Button type="primary" htmlType="submit">
                  <span>Qo'shish</span>
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    </>
  );
};
export default ZapchastMainPage;
