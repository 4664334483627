import { Route, Routes } from "react-router";
import AdminAbout from "../components/admin-about";
import Sidebar from "../components/sidebar";
import BrendsPage from "../pages/Brends-page";
import CategoriesPage from "../pages/categories-page";
import NewsPage from "../pages/news-page";
import ProductsPage from "../pages/products-page";
import BannersPage from '../pages/Banners';
import SubCategoriesPage from "../pages/sub-category";
import Orders from "../pages/order";
import CountdownTimer from "../pages/count";
import ZapchastPage from "../pages/zapchast";
import ZapchastMainPage from "../pages/zapchast-page";

const ProfileLayout = () => {


  return (
    <>
      <div className="container-xxl">
        <div
          className="admin-page"
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          <div
            className="admin-page-left"
            style={{ width: "15%", height: "100vh" }}
          >
            <Sidebar />
          </div>
          <div
            className="admin-page-right"
            style={{
              padding: "36px 108px 0 36px",
              width: "85%",
              height: "100vh",
              overflowY: "scroll",
            }}
          >
            <div className="right-top">
              <AdminAbout />
            </div>
            <div className="right-bottom" style={{ paddingBottom: "136px" }}>
              <Routes>
                <Route path="/news" element={<NewsPage />} />
                <Route path="/zapchast/products" element={<ZapchastPage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/category" element={<CategoriesPage />} />
                <Route path="/zapchast" element={<ZapchastMainPage />} />
                <Route path="/category/:id" element={<SubCategoriesPage />} />
                <Route path="/brends" element={<BrendsPage />} />
                <Route path="/banners" element={<BannersPage />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/count" element={<CountdownTimer />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileLayout;
