import cardImg from "../assets/images/google(2).png";
import cardImgSecond from "../assets/images/pinterest2.png";
import cardImgThird from "../assets/images/spotify2.png";
import "./news-page.scss";
import { useEffect, useState } from "react";
import { Form, Input, Modal, Spin } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import axios from "axios";
import { useUserContext } from "../context/dashboard-context";
import ImageUploader from "../components/uploader";
import BrandCard from "../components/product-card/brand-card";
import CustomPagination from "../components/pagination";
import { useImageUpload } from "../context/ImageUploadContext";

const BrendsPage = () => {
  const { token } = useUserContext();
  const { images ,clearImages} = useImageUpload();
  const [brands, setBrands] = useState();
  const [countPage, setCountPage] = useState();
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("page")),
    1
  );
  const [slug, setSlug] = useState();

  const [totalProducts, setTotalProducts] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const GetBrands = (fuckingPageNumber) => {
    setLoading(true);
    axios
      .get(
        `https://api.protool.uz/v1/brands?page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setBrands(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };
  useEffect(() => {
    GetBrands();
  }, []);
  const handlePageChange = (page) => {
    console.log(page, "page");
    setCurrentPage(page);
    GetBrands(page);
  };

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const AddCategory = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.protool.uz/v1/brands",
        {
          name: value,
          image: images[0],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200 || response.status === 201) {
        // window.location.reload();
        GetBrands();
        handleCancel();
        setLoading(false);
        clearImages()
      }
      // setCategory();
    } catch (error) {
      console.error(error);
      setLoading(false);

      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "CATEGORY_NOT_FOUND"
      ) {
        console.log("Category not found");
      } else {
        console.log(error.response?.data.data);
      }
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const [clear, setClear] = useState(false);
  const handleCancel = () => {
    clearImages()
    setIsModalOpen(false);
    setClear(true);
    form.resetFields();
  };
  const data = [
    {
      img: cardImgThird,
      title: "Akfa",
      description: false,
    },
    {
      img: cardImg,
      title: "Google",
      description: false,
    },
    {
      img: cardImgSecond,
      title: "Pinterest",
      description: false,
    },
    {
      img: cardImgThird,
      title: "Spotify",
      description: false,
    },
  ];
  const [form] = Form.useForm();

  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Brendlar</h1>
          <button onClick={showModal}>+ Brend qo’shish</button>
        </div>
        <div className="page-cards">
          {brands?.map((i, k) => (
            <BrandCard key={data?.id} data={i} handleRefatch={GetBrands} />
          ))}
        </div>
        <CustomPagination
          func={GetBrands}
          current={currentPage}
          defaultCurrent={1}
          total={totalProducts}
          onChange={handlePageChange}
          setCurrentPage={setCountPage}
        />
      </div>
      <div className="modal-news">
        <Modal
          title="Brend qo’shish"
          open={isModalOpen}
          // onOk={handleOk}
          onCancel={handleCancel}
        >
          <Spin spinning={loading} delay={0}>
            <div className="modal-body">
              <div className="body-img">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={() => {
                    AddCategory();
                    // handleOk();
                  }}
                  action=""
                >
                  <ImageUploader clear={clear} />
                  {/* <label htmlFor="title">Mavzu</label>
                <input
                  type="text"
                  name=""
                  onChange={(e) => setValue(e.target.value)}
                  id="title"
                  placeholder="Mavzu nomi"
                  required
                /> */}

                  <Form.Item
                    label="Nomi uz"
                    name="Nomi"
                    rules={[{ required: true, message: "Nomi is required" }]}
                  >
                    <Input
                      placeholder="Nomi"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </Form.Item>
                  <div className="modal-footer">
                    <button onClick={handleCancel}>
                      <span>Bekor qilish</span>
                    </button>
                    <button>
                      <span>Qo'shish</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </>
  );
};
export default BrendsPage;
