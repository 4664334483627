import React, { useState, useEffect } from "react";
import { Modal, Input, message, Select, Switch, Spin } from "antd";
import { Link } from "react-router-dom";
import { DeleteIcon, Pen } from "../../assets/images/icons";
import "./product-card.scss";
import { useUserContext } from "../../context/dashboard-context";
import axios from "axios";
import { Image } from "antd";
import Spinner from "../loading";
import ImageEditeUploader from "../imageEditeUploader";
const { Option } = Select;

const ZapchastCard = ({
  img,
  description,
  title,
  id,
  type,
  price,
  featuredproduct,
  zapchast_id,
  title_ru,
  description_ru,
  brand,
  status,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedTitleRu, setUpdatedTitleRu] = useState(title_ru);
  const [featured, setFeatured] = useState(featuredproduct);
  const [excerpt, setExcerpt] = useState(status);
  const [updatedBrand, setUpdatedBrand] = useState(brand);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [updatedDescriptionRu, setUpdatedDescriptionRu] =
    useState(description_ru);
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [updatedImage, setUpdatedImage] = useState(img);
  const [categoryId, setCategoryId] = useState(zapchast_id);
  const [imageFile, setImageFile] = useState(null);
  useEffect(() => {
    setUpdatedBrand(brand);
    setUpdatedTitleRu(title_ru);
    setUpdatedDescription(description);
    setUpdatedDescriptionRu(description_ru);
    setUpdatedImage(img);
    setCategoryId(zapchast_id);
    setUpdatedTitle(title);
    setExcerpt(status);
  }, [title]);
  const {
    token,
    // GetCategory,
    images,
    GetNews,
    GetBrands,
    zapchastproducts,
    setZapchastProducts,
    brands,
  } = useUserContext();
  const [categorys, setCategorys] = useState();
  const GetCategory = () => {
    setLoading(true);
    axios
      .get(`https://back2.protools.uz/api/zapchast/`, {})
      .then((response) => {
        console.log(response.data.data, "misha");
        setLoading(false);
        setCategorys(response?.data?.data);
      });
  };
  useEffect(() => {
    if (isEditMode) {
      GetCategory();
    }
  }, [categoryId, isEditMode]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const GetProducts = (fuckingPageNumber) => {
    setLoading(true);
    axios
      .get(`https://back2.protools.uz/api/maxsulot/?include=zapchast`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoading(false);
        setZapchastProducts(response?.data?.data);
        // setTotalProducts(response?.data?.totalCount);
        // setPagesCount(response?.data?.pagesCount);
      });
  };

  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setUpdatedImage(null)
    setIsModalOpen(false);
    // GetProducts()
    setIsEditMode(false);
  };

  const removeProduct = async () => {
    try {
      const formData = new FormData();
      formData.append("maxsulot_id", id);

      const response = await axios.delete(
        "https://back2.protools.uz/api/maxsulot/",
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Delete response:", response.data);
      GetProducts();
      // Add further handling if needed
    } catch (error) {
      console.error("Error deleting data:", error);
      // Add error handling
    }
  };

  const [loading, setLoading] = useState(false);
  // const updateProduct = (imageUrl) => {
  //     setLoading(true);

  //     const requestBody = {
  //         zapchast_id: categoryId,
  //         name_uz: updatedTitleRu || title_ru,
  //         name_ru: updatedTitle || title,
  //         description: updatedDescription || description,
  //         price: updatedPrice ? parseFloat(updatedPrice) : price,
  //         category: categoryId,
  //         brand: updatedBrand ? updatedBrand : brand,
  //         description_ru: updatedDescriptionRu || description_ru,
  //         image: imageUrl || img, // Use imageUrl if provided, otherwise use existing image
  //     };

  //     axios
  //         .post("https://back2.protools.uz/api/maxsulot/", requestBody, {
  //             headers: {
  //                 Authorization: `Bearer ${token}`,
  //                 "Content-Type": "application/json",
  //             },
  //         })
  //         .then((response) => {
  //             console.log("Product updated successfully:", response.data);
  //             GetProducts();
  //             // form.resetFields();
  //             setLoading(false);
  //             message.success("Mahsulot yangilandi");
  //         })
  //         .catch((error) => {
  //             setLoading(false);
  //             console.error("Error updating product:", error);
  //             // Handle error
  //         });
  // };

  const updateProduct = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name_ru", updatedTitleRu || title_ru);
    formData.append("name_uz", updatedTitle || title);
    formData.append("description", updatedDescription || description);
    formData.append("price", updatedPrice ? parseFloat(updatedPrice) : price);
    formData.append("zapchast_id", categoryId);
    formData.append("brand", updatedBrand ? updatedBrand : brand);
    formData.append("description_ru", updatedDescriptionRu || description_ru);
    formData.append("brand_image", img);
    formData.append("maxsulot_id", id);
    formData.append("status", "active");
    formData.append("image", updatedImage ? updatedImage : img); // Use imageUrl if provided, otherwise use existing image
    axios
      .put(
        `https://back2.protools.uz/api/maxsulot/?include=zapchast&filter[zapchast_id]=${categoryId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // Don't need to set Content-Type for FormData
          },
        }
      )
      .then((response) => {
        console.log("Product updated successfully:", response.data);
        GetProducts();
        // window.location.reload()
        handleCancel();
        setLoading(false);
        message.success("Mahsulot yangilandi");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error updating product:", error);
        // Handle error
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(e, "value");
    setUpdatedImage(URL.createObjectURL(file));
    setImageFile(file);
  };
  const [fuckingPrice, setFuckingPrice] = useState(price);
  return (
    <>
      <td>{title}</td>
      <td>
        {description?.length > 80
          ? description.slice(0, 30) + "..."
          : description}
      </td>
      <td>
        {price === 0 ? " " : new Intl.NumberFormat().format(price) + " so'm"}
      </td>
      <td>
        <Image
          style={{
            objectFit: "contain",
          }}
          width={80}
          height={40}
          src={img}
        />
      </td>
      <td>
        <button
          style={{
            border: "none",
            outline: "none",
            background: "transparent",
          }}
          onClick={showEditForm}
        >
          <Pen />
        </button>
        <button
          style={{
            border: "none",
            outline: "none",
            background: "transparent",
          }}
          onClick={removeProduct}
        >
          <DeleteIcon />
        </button>
      </td>
      <Modal
        title={isEditMode ? "Update Product" : "Delete Product"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Spin spinning={loading}>
          {isEditMode ? (
            <div className="product_edit_card">
              <p className="product_edit_card_info">
                {(() => {
                  switch (type) {
                    case "products":
                      return "Mahsulotlarni";
                    case "brands":
                      return "Brandni";
                    case "categories":
                      return "Kategoriyani";
                    case "articles":
                      return "Yangilikni";
                    default:
                      return "o'zgartirish";
                  }
                })()}{" "}
                o'zgartirish
              </p>

              {type === "brands" ? (
                <>
                  <label htmlFor="title" className="product_edit_card_title">
                    Brand Nomi
                    {/* <Input
                                        id="title"
                                        name="title"
                                        placeholder="Brand Name"
                                        value={updatedTitle}
                                        onChange={(e) => setUpdatedTitle(e.target.value)}
                                        style={{ marginBottom: "16px" }}
                                        required
                                    /> */}
                    <select
                      onChange={(e) => setUpdatedBrand(e.target.value)}
                      name=""
                      id=""
                    >
                      {brands?.map((item, key) => (
                        <option value="">{item?.name}</option>
                      ))}
                    </select>
                  </label>
                </>
              ) : (
                <>
                  {loading && <p>Iltimos kuting...</p>}
                  <ImageEditeUploader
                    setUpdatedImage={setUpdatedImage}
                    updatedImage={updatedImage ? updatedImage : img}
                    token={token}
                  />
                  {/*                                 
                                <div className="my-3 d-flex justify-content-between">
                                    <label>
                                        {" "}
                                        Top {type === "products" ? "mahsulot" : "Category"}
                                    </label>
                                    <Switch
                                        onChange={() => setFeatured(!featured)}
                                        checked={featured}
                                    />
                                </div> */}

                  {/* <div className="my-3 d-flex justify-content-between">
                                    <label> Sotuvda mavjud</label>
                                    <Switch
                                        onClick={() =>
                                            setSwitch(switchbutton === "true" ? false : true)
                                        }
                                        defaultChecked={switchbutton == "true" ? true : false}
                                    />
                                </div> */}
                  <div className="my-3 d-flex justify-content-between">
                    <label> Sotuvda mavjud</label>
                    <Switch
                      defaultChecked={excerpt}
                      onClick={() => setExcerpt(!excerpt)}
                    />
                  </div>
                  <label htmlFor="title" className="product_edit_card_title">
                    Nomi uz
                    <Input
                      id="title"
                      name="title"
                      placeholder="Title"
                      value={updatedTitle}
                      onChange={(e) => setUpdatedTitle(e.target.value)}
                      style={{ marginBottom: "16px" }}
                      required
                    />
                  </label>
                  <label htmlFor="title" className="product_edit_card_title">
                    Nomi ru
                    <Input
                      id="title"
                      name="title"
                      placeholder="Title"
                      value={updatedTitleRu}
                      onChange={(e) => setUpdatedTitleRu(e.target.value)}
                      style={{ marginBottom: "16px" }}
                      required
                    />
                  </label>

                  {type === "products" && (
                    <label htmlFor="price" className="product_edit_card_title">
                      Narxi
                      <Input
                        id="price"
                        name="price"
                        placeholder="Price"
                        type="text"
                        value={updatedPrice ? updatedPrice : fuckingPrice}
                        onChange={(e) => {
                          setUpdatedPrice(e.target.value);
                          setFuckingPrice(e.target.value);
                        }}
                        style={{ marginBottom: "16px" }}
                        // required
                      />
                    </label>
                  )}

                  <div>
                    <label className="product_edit_card_title" htmlFor="">
                      {" "}
                      Zapchast nomi
                    </label>
                    <select
                      required
                      id="category"
                      value={categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                      style={{
                        marginBottom: "16px",
                        width: "100%",
                        padding: "12px 8px",
                        borderRadius: "8px",
                      }}
                    >
                      {categorys?.map((cat) => (
                        <option
                          selected={cat.id === id}
                          key={cat.id}
                          value={cat.id}
                        >
                          {cat.name_uz}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label htmlFor="title" className="product_edit_card_title">
                    Brand Nomi
                    <select
                      onChange={(e) => setUpdatedBrand(e.target.value)}
                      style={{
                        marginBottom: "16px",
                        width: "100%",
                        padding: "12px 8px",
                        borderRadius: "8px",
                      }}
                      name=""
                      id=""
                    >
                      {brands?.map((item, key) => (
                        <>
                          <option
                            selected={item.name === brand}
                            value={item.name}
                          >
                            {item?.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </label>

                  <label
                    htmlFor="description"
                    className="product_edit_card_title"
                  >
                    Matn uz
                    <Input.TextArea
                      id="description"
                      name="description"
                      placeholder="Description"
                      value={updatedDescription}
                      onChange={(e) => setUpdatedDescription(e.target.value)}
                      style={{ marginBottom: "16px", resize: "none" }}
                      required
                    />
                  </label>
                  <label
                    htmlFor="description_ru"
                    className="product_edit_card_title"
                  >
                    Matn ru
                    <Input.TextArea
                      id="description_ru"
                      name="description_ru"
                      placeholder="Tafsifi ru"
                      value={updatedDescriptionRu}
                      onChange={(e) => setUpdatedDescriptionRu(e.target.value)}
                      style={{ marginBottom: "16px", resize: "none" }}
                      required
                    />
                  </label>
                </>
              )}
              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button
                  className="product_edit_card_save"
                  onClick={updateProduct}
                >
                  <span>Saqlash</span>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>Siz rostdan ham ushbu mahsulotni o’chirmoqchimisiz?</p>
              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button className="delete-btn" onClick={removeProduct}>
                  <span>O'chirish</span>
                </button>
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default ZapchastCard;
