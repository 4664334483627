import { Skeleton } from "antd";
import axios from "axios";
import { useState } from "react";
export default function ImageEditeUploader({setUpdatedImage,updatedImage,token}) {
    const [loader,setLoader]=useState(false)
    const handleImageChange = (e) => {
        setLoader(true);
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        axios
          .post("https://api.protool.uz/v1/file-upload", formData, {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const imageUrl = response.data?.data.url;
            setUpdatedImage(imageUrl);
            setLoader(false);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            setLoader(false);
          });
      };
    return(
        <>
        {loader ? (
          <Skeleton.Image active={loader} />
        ) : (
          <img
          className="imageStyle"
            src={updatedImage}
            alt="card image"
          />
        )}
        <label className="product_edit_card_input_label" htmlFor={Date.now()}>
          Boshqa rasm yuklash
          <input
            onChange={handleImageChange}
            type="file"
            name="img"
            id={Date.now()}
            key={Date.now()}
            className="product_edit_card_input"
            accept="image/png, image/jpeg image/jpg"
            multiple={true}
          />
        </label>
      </>
    )
    
}