import React, { useState } from "react";
import { Upload, Button, message, Image, Skeleton } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useImageUpload } from "../../context/ImageUploadContext";
import { useUserContext } from "../../context/dashboard-context";
import { Loader2 } from "lucide-react";

import "./main.scss";
const ImageUpload = () => {
  const { addImage, clearImages, images } = useImageUpload();
  const { token, fileList, setFileList } = useUserContext();
  const [loader, setLoader] = useState(false);

  const handleUpload = async ({ file }) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch("https://api.protool.uz/v1/file-upload", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log(data, "polat");
      addImage(data?.data?.url);
      setLoader(false);
      message.success("Yuklash muvaffaqiyatli amalga oshirildi!");
    } catch (error) {
      setLoader(false);
      console.error("Faylni yuklashda xatolik yuz berdi:", error);
      message.error("Yuklash muvaffaqiyatsiz tugadi.");
    }
  };
  return (
    <div>
      {images.length == 0 && (
        <Upload
          fileList={fileList}
          onChange={handleUpload}
          beforeUpload={() => false}
          accept="image/png, image/jpeg image/jpg"
          multiple
        >
          <Button icon={<UploadOutlined />}>Fayllarni tanlash</Button>
        </Upload>
      )}
      <div className="listImages" style={{ marginTop: 16 }}>
        {images.map((file) => (
          <div>
            <Image
              key={file.uid}
              src={file}
              alt={file.name}
              style={{ marginRight: 8, marginBottom: 8, maxWidth: 200 }}
            />
            <div style={{ marginBottom: 16 }}>
              <Button
                type="default"
                onClick={() => {
                  clearImages(file);
                }}
                style={{ marginRight: 8 }}
              >
                O'chirish
              </Button>
            </div>
          </div>
        ))}
        {loader && <Skeleton.Image active={true} />}
      </div>
    </div>
  );
};

export default ImageUpload;
